import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class OptionService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/option`;
    }

    getByKey(key) {
        let url = `${this.#api}/${key}`;
        return apiService.get(url);
    }
    getByKeys(data) {
        let url = `${this.#api}/get-by-keys`;
        let param ={ params: data}
        return apiService.query(url,param);
    }

    convertToBs(date) {
        let url = `${this.#api}/convert/to-bs?date=${date}`;
        return apiService.get(url);
    }
    getAllInvoiceOption(){
        let url = `${this.#api}/get/all/invoice`;
        return apiService.get(url);
    }
    updateOption(data) {
        let url = `${this.#api}/update/all`;
        return apiService.post(url, data)
    }
    updateOptionByKey(data) {
        let url = `${this.#api}/update/by/key`;
        return apiService.post(url, data)
    }
}
